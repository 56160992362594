var head = document.getElementsByTagName('HEAD')[0];

// Create new link Element
var materialCss = document.createElement('link');
materialCss.rel = 'stylesheet';
materialCss.type = 'text/css';
materialCss.href = 'https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.css';

function removeElement(elementId) {
  // Removes an element from the document
  var element = document.getElementById(elementId);
  element.parentNode.removeChild(element);
}

function createBrowserError(browser) {
  removeElement('main');
  head.appendChild(materialCss);
  document.write('<div id="browser-error" class="container fade hidden">');
  document.write('<br>');
  document.write('<br>');
  document.write('<hr style="margin: 10px;">');
  document.write('<br>');
  document.write('<br>');
  document.write('<h6 class="flow-text">Whoops!</h6>');
  document.write('<h3 id="browser" class="red-text text-red">' + browser + ' is not a supported browser.</h3>');
  document.write('<h6 class="flow-text">');
  document.write(
    'Thank you for coming to Catalyst corporate CECLution. This website does not support ' +
      browser +
      '. Please use one of the following browsers.'
  );
  document.write('</h6>');
  document.write('<ul>');
  document.write('<li>Google Chrome</li>');
  document.write('<li>Microsoft Edge</li>');
  document.write('<li>Mozilla Firefox</li>');
  document.write('<li>Safari (macOS)</li>');
  document.write('</ul>');
  document.write('</div>');
}

/* Sample function that returns boolean in case the browser is Internet Explorer*/
function isIE() {
  ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  return is_ie;
}

/* Create an alert to show if the browser is IE or not */
if (isIE()) {
  createBrowserError('Internet Explorer');
}

if (/Edge/.test(navigator.userAgent)) {
  createBrowserError('Microsoft Edge');
}
